import React, { useState, useEffect } from "react";
import { RxCaretDown } from "react-icons/rx";
import { IoIosSearch } from "react-icons/io";
import { CiDiscount1 } from "react-icons/ci";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { FiUser } from "react-icons/fi";
import { PiShoppingCartSimple } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";

export default function Header() {
    // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual Google Maps API Key
    const API_KEY = "2752f80587097a2b6024869765254f52";

    const [toggle, setToggle] = useState(false); // State for left menu
    const [toggle2, setToggle2] = useState(false); // State for right menu (Sign In)
    const navigate = useNavigate();

    // Functions to show and hide left-side menu
    const showSideMenu = () => {
        setToggle(true);
    };

    const hideSideMenu = () => {
        setToggle(false);
    };

    // Functions to show and hide right-side Sign In menu
    const showSideMenu2 = () => {
        setToggle2(true);
        console.log("Side menu opened");
    };

    const hideSideMenu2 = () => {
        setToggle2(false);
    };

    const links = [
        {
            icon: <IoIosSearch />,
            name: "Search",
            path: "/Search"
        },
        {
            icon: <CiDiscount1 />,
            name: "Offers",
            sup: "New",
            path: "/offers"
        },
        {
            icon: <LiaHandsHelpingSolid />,
            name: "Help",
            path: "/help"
        },
        {
            icon: <FiUser />,
            name: "Sign In",
            path: "/SignIn"
            // onClick: showSideMenu2 // Trigger right-side menu when Sign In is clicked
        },
        {
            icon: <PiShoppingCartSimple />,
            name: "Cart",
            sup: "(0)",
            path: "/cart"
        },
    ];

    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
    });

    const [address, setAddress] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        if (toggle) {
            getLocation();  // Call getLocation when side menu opens
        }
    }, [toggle]);

    // Fetch saved address from localStorage when component mounts
    useEffect(() => {
        const savedAddress = localStorage.getItem('savedAddress');
        if (savedAddress) {
            setAddress(savedAddress);
        }
    }, []);

    // Function to get user's current location
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (err) => setError(err.message)
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };

    // Function to get address from latitude and longitude
    const getAddressFromCoords = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://api.positionstack.com/v1/reverse?access_key=${API_KEY}&query=${lat},${lng}`
            );
            const data = await response.json();
            console.log(data, '============================');
            if (data.data && data.data.length > 0) {
                const fetchedAddress = data.data[0].label; // `label` contains the formatted address
                setAddress(fetchedAddress);

                // Save the fetched address to localStorage
                localStorage.setItem('savedAddress', fetchedAddress);
            } else {
                setError("Unable to find the address.");
            }
        } catch (error) {
            setError("Error fetching address from API.");
        }
    };

    // Function to fetch GPS address on button click
    const fetchGPSAddress = () => {
        if (location.latitude && location.longitude) {
            getAddressFromCoords(location.latitude, location.longitude);
        }
    };


    return (
        <>
            {/* Left-side menu overlay (Jaipur, Rajasthan) */}
            <div className="black-overlay w-full h-full fixed duration-500 z-40"
                style={{ opacity: toggle ? 1 : 0, visibility: toggle ? "visible" : "hidden", }}
            >
                <div className="w-[500px] bg-white h-full absolute duration-[400ms]"
                    style={{
                        left: toggle ? "0%" : "-100%"
                    }}
                >
                    {/* Left side menu content */}
                    <div className="p-8">
                        <div className="w-full float-left">
                        <button
                            onClick={hideSideMenu}
                            className="px-4 py-2 bg-red-500 text-white rounded float-right"
                        >
                            <MdOutlineClose />
                        </button>
                        </div>


                        <h2 className="text-xl font-bold">Current Your Address</h2>

                        {/* Custom address input */}
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                Custom Address:
                            </label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                placeholder="Enter address manually"
                            />
                        </div>

                        {error ? (
                            <p className="text-red-500">Error: {error}</p>
                        ) : (
                            <div>
                                {location.latitude && location.longitude && (
                                    <p>
                                        Latitude: {location.latitude}, Longitude:{" "}
                                        {location.longitude}
                                    </p>
                                )}
                            </div>
                        )}

                        <button
                            onClick={fetchGPSAddress}
                            className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
                        >
                            Get Address from GPS
                        </button>

                        <p className="mt-4">
                            <strong>Address: </strong>
                            {address || "No address found"}
                        </p>


                    </div>
                </div>
            </div>

            {/* Right-side Sign In menu overlay */}
            <div className="black-overlay w-full h-full fixed duration-500 z-40" onClick={hideSideMenu2}
                style={{ opacity: toggle2 ? 1 : 0, visibility: toggle2 ? "visible" : "hidden", }}
            >
                <div className="w-[500px] bg-white h-full absolute duration-[400ms]"
                    style={{
                        right: toggle2 ? "0%" : "-100%" // Right-side sliding menu
                    }}
                >
                    {/* Right side menu content (Sign In menu) */}
                    <h2>Sign In Menu</h2>
                    <p>Here you can put the sign in options or any content.</p>
                </div>
            </div>

            <header className='p-[10px] shadow-xl'>
                <div className="max-w-[80%] mx-auto flex items-center">
                    <div className="w-[60px]">
                        <Link to="/">
                            <img src="images/logo.png" className="w-full" alt="logo" />
                        </Link>
                    </div>
                    <div className="ml-[10px] cursor-pointer" onClick={showSideMenu} >
                        <span className="font-bold border-b-[2px] border-[black]">Other</span> {address}
                        <RxCaretDown className="inline text-[25px] text-[#e11616]" />
                    </div>
                    <nav className="flex list-none gap-10 text-[18px] items-center ml-auto font-semibold">
                        {links.map((link, index) => (
                            <li key={index} className="cursor-pointer flex text-[18px] items-center gap-2">
                                {/* If link.path exists, use NavLink, otherwise just a div */}
                                {link.path ? (
                                    <NavLink
                                        to={link.path}
                                        className={({ isActive }) =>
                                            `flex items-center gap-2 hover:text-[#e11616] ${isActive ? "text-[#e11616]" : "text-black"}`
                                        }
                                    >
                                        {link.icon}
                                        {link.name}
                                        {link.sup && <sup className="text-[#e11616]">{link.sup}</sup>}
                                    </NavLink>
                                ) : (
                                    <div onClick={link.onClick} className="flex items-center gap-2 hover:text-[#e11616] text-black">
                                        {link.icon}
                                        {link.name}
                                    </div>
                                )}
                            </li>
                        ))}
                    </nav>
                </div>
            </header>
        </>
    );
}
